body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  list-style-type: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f4f4f4;
  font-family: sans-serif;
}

ol {
  margin: 0;
  padding: 0;
}

header.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 20px;
}

header.profile img {
  width: 80px;
  height: 80px;

  border-radius: 50%;
  border: 2px solid #f4f4f4;
  background: #f4f4f4;
}

header.profile p {
  font-weight: bold;
  color: #f4f4f4;
  margin-bottom: 0;
}

header.profile span {
  font-size: 12px;
  text-decoration: underline;
  color: #afafaf;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carrier-logo {
  margin: 10px 10px;
  height: 40px;
}

.logo {
  margin: 10px;
  opacity: 0.4;
}

.sidebar {
  z-index: 9999;
  box-sizing: content-box;
  background: #141d2e;
  position: fixed;
  width: 16rem;
  height: 100%;
  box-shadow: 2px 2px 20px #4392cf;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
}

.sidebar.inactive {
  box-shadow: 0px 0px 0px #4392cf;

  transform: translateX(-16rem);
  -webkit-transform: translateX(-16rem);
  -moz-transform: translateX(-16rem);
  -ms-transform: translateX(-16rem);
  -o-transform: translateX(-16rem);
}

.hideMenu {
  background: #141d2e;
  position: absolute;
  top: 0px;
  right: -30px;
  height: 30px;
  width: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 4px;
}

.search {
  appearance: none;
  background: #33476f;
  border: 0px;
  margin-top: 10px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  display: block;
  margin-right: -10px;
}

.angles {
  height: 1.5rem;
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
  width: 100%;
  height: auto;
}

.angles.right {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.bmb {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.app {
  display: flex;
  height: 100%;
  margin-top: 1px #efefef solid;
}

.menu {
  height: calc(100vh - 277px);
}

.menu ol {
  flex-flow: column;
  overflow: auto;
  max-height: calc(100vh - 315px);
}

.menu-item {
  width: 100%;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}

.menu-item button {
  cursor: pointer;
  color: #f4f4f4;
  font-size: 1rem;
  background-color: #141d2e;
  display: block;
  padding: 1rem;
  border: 0;
  padding: 0.75rem 1rem;
}

.menu-item button:last-child {
  width: 100%;
}

.menu-item button:hover {
  background: #33476f;
}

.menu-item {
  position: relative;
  border-bottom: 1px #33476f solid;
  border-top: 1px #33476f solid;
  display: flex;
}

.children-indicator {
  position: absolute;
  right: 1rem;
  top: calc(50% - 0.5rem);
  color: #fff;
}

.breadcrumb {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0.5rem;
  right: -100%;
  width: 100%;
  border-radius: 0 5px 5px 0;
  text-align: center;
  background-color: #33476faa;
  font-size: 0.8rem;
  white-space: ellipsis;
  transition: all ease-in-out 0.2s;
}

.breadcrumb:hover {
  color: #fff;
  background-color: #33476f;
  font-size: 1rem;
}

.active {
  background: #00ade5;
  border-right: 5px solid #4392cf;
  color: #fff;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}

.report {
  width: calc(100vw - 16rem);
  height: 100vh;
  z-index: 0;
  background: #fff;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transform: translateX(16rem);
  -webkit-transform: translateX(16rem);
  -moz-transform: translateX(16rem);
  -ms-transform: translateX(16rem);
  -o-transform: translateX(16rem);
}

.report.expanded {
  width: 100vw;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.report iframe {
  width: 100%;
  height: 100%;
  border: 0;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
}

.base-logout {
  display: flex;
  justify-content: center;
  border-bottom: 1px #33476f solid;
  border-top: 1px #33476f solid;
}

.base-logout:hover {
  background: #33476f;
}

.base-logout button {
  cursor: pointer;
  color: #f4f4f4;
  font-size: 1rem;
  background-color: inherit;
  display: block;
  padding: 1rem;
  border: 0;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: center;
}
